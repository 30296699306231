export const toQueryString = (params: any): string => {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null) // Check the value instead of the key
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        // Return an array of key/value pairs for each item in the array
        return value.map(
          (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
        );
      } else {
        // Return a single key/value pair for non-array values
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          value as string
        )}`;
      }
    })
    .join('&');
};
