import { config } from './common-config';

export const environment = {
  production: false,
  firebase: {
    apiKey: "#{FIREBASE_API_KEY}#",
    authDomain: "#{FIREBASE_AUTH_DOMAIN}#",
    projectId: "#{FIREBASE_PROJECT_ID}#",
    storageBucket: "#{FIREBASE_STORAGE_BUCKET}#",
    messagingSenderId: "#{FIREBASE_MESSAGING_SENDER_ID}#",
    appId: "#{FIREBASE_APP_ID}#",
    measurementId: "#{FIREBASE_MEASUREMENT_ID}#",
  },
  api: {
    baseUrl: 'https://api-dev.resqueue.com.ph',
  },
  ...config
};
